/**
 * A translation for a route, specifying the language and path.
 * @typedef {Object} Translation
 * @property {string} lang - The language code (e.g., 'en', 'sk').
 * @property {string} path - The path for the route in the specified language.
 * @property {boolean} [index] - Optional boolean to indicate a default route.
 * @property {RouteDefinition[]} [nestedRoutes] - Optional array of nested routes.
 */

// @ts-check

/**
 * Represents a single route in the application, including its translations and potential nested routes.
 * @typedef {Object} RouteDefinition
 * @property {string} id - Unique identifier for the route.
 * @property {string} file - The file path associated with the route.
 * @property {Translation[]} translations - Array of translations for the route.
 */

/**
 * The routes configuration for the application.
 * @type {RouteDefinition[]}
 */
export const routes = [
  {
    id: "home",
    file: "pages/home.tsx",
    translations: [
      {
        lang: "sk",
        path: "",
        index: true,
      },
      {
        lang: "en",
        path: "home",
      },
    ],
  },
  {
    id: "articles",
    file: "pages/articles.tsx",
    translations: [
      {
        lang: "sk",
        path: "novinky",
      },
      {
        lang: "en",
        path: "news",
      },
    ],
  },
  {
    id: "new-tshirt-form",
    file: "pages/new-tshirt-form.tsx",
    translations: [
      {
        lang: "sk",
        path: "ziadost-o-tricko-formular",
      },
      {
        lang: "en",
        path: "t-shirt-request-form",
      },
    ],
  },
  {
    id: "maps",
    file: "pages/maps.tsx",
    translations: [
      {
        lang: "sk",
        path: "mapy",
      },
      {
        lang: "en",
        path: "maps",
      },
    ],
  },
  {
    id: "program",
    file: "pages/program.tsx",
    translations: [
      {
        lang: "sk",
        path: "program",
      },
      {
        lang: "en",
        path: "program",
      },
    ],
  },
  {
    id: "article",
    file: "pages/articles_.$slug.tsx",
    translations: [
      {
        lang: "sk",
        path: "novinky/:slug",
      },
      {
        lang: "en",
        path: "news/:slug",
      },
    ],
  },
  {
    id: "interprets",
    file: "pages/interprets.tsx",
    translations: [
      {
        lang: "sk",
        path: "interpreti",
      },
      {
        lang: "en",
        path: "interprets",
      },
    ],
  },
  {
    id: "shop",
    file: "pages/shop.tsx",
    translations: [
      {
        lang: "sk",
        path: "obchod",
        nestedRoutes: [
          {
            id: "product",
            file: "pages/shop.$slug.tsx",
            translations: [
              {
                lang: "sk",
                path: "produkt/:slug",
              },
            ],
          },
        ],
      },
      {
        lang: "en",
        path: "shop",
        nestedRoutes: [
          {
            id: "product",
            file: "pages/shop.$slug.tsx",
            translations: [
              {
                lang: "en",
                path: "product/:slug",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "cart",
    file: "pages/cart.tsx",
    translations: [
      {
        lang: "sk",
        path: "kosik",
        nestedRoutes: [
          {
            id: "cart-index",
            file: "pages/cart._index.tsx",
            translations: [
              {
                lang: "sk",
                path: "",
                index: true,
              },
            ],
          },
          {
            id: "cart-details",
            file: "pages/cart.details.tsx",
            translations: [
              {
                lang: "sk",
                path: "platba-a-dorucenie",
                index: true,
              },
            ],
          },
        ],
      },
      {
        lang: "en",
        path: "cart",
        nestedRoutes: [
          {
            id: "cart-index",
            file: "pages/cart._index.tsx",
            translations: [
              {
                lang: "en",
                path: "",
                index: true,
              },
            ],
          },
          {
            id: "cart-details",
            file: "pages/cart.details.tsx",
            translations: [
              {
                lang: "en",
                path: "payment-and-delivery",
                index: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "camping",
    file: "pages/camping.tsx",
    translations: [
      {
        lang: "sk",
        path: "kempovanie",
      },
      {
        lang: "en",
        path: "camping",
      },
    ],
  },
  {
    id: "order-status",
    file: "pages/order.status.tsx",
    translations: [
      {
        lang: "sk",
        path: "objednavka",
      },
      {
        lang: "en",
        path: "order",
      },
    ],
  },
  {
    id: "email-order-created",
    file: "pages/emails.order-created.tsx",
    translations: [
      {
        lang: "sk",
        path: "api/emails/order-created",
      },
    ],
  },
  {
    id: "email-tshirt-request-accepted",
    file: "pages/emails.tshirt-request.tsx",
    translations: [
      {
        lang: "sk",
        path: "api/emails/tshirt-request-accepted",
      },
    ],
  },
  {
    id: "email-tshirt-request-rejected",
    file: "pages/emails.tshirt-request.tsx",
    translations: [
      {
        lang: "sk",
        path: "api/emails/tshirt-request-rejected",
      },
    ],
  },
  {
    id: "email-order-qr",
    file: "pages/emails.order-qr.tsx",
    translations: [
      {
        lang: "sk",
        path: "api/emails/order-qr",
      },
    ],
  },
  {
    id: "qr",
    file: "pages/qr.tsx",
    translations: [
      {
        lang: "sk",
        path: "api/qr",
      },
    ],
  },
  {
    id: "email-order-sent",
    file: "pages/emails.order-sent.tsx",
    translations: [
      {
        lang: "sk",
        path: "api/emails/order-sent",
      },
    ],
  },
  {
    id: "invoice",
    file: "pages/invoice.tsx",
    translations: [
      {
        lang: "sk",
        path: "api/invoice",
      },
    ],
  },
  {
    id: "catch-all",
    file: "pages/$.tsx",
    translations: [
      {
        lang: "sk",
        path: "*",
      },
      {
        lang: "en",
        path: "*",
      },
    ],
  },
];
