import { Link, NavLink, useNavigation } from "@remix-run/react";
import type {
  RemixLinkProps,
  RemixNavLinkProps,
} from "@remix-run/react/dist/components";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useLocale } from "remix-i18next";
import { getLinkByRoute } from "~/hooks/useLocaleNavigate";
import { cn } from "~/utils";

interface LocaleLinkProps
  extends Omit<RemixLinkProps, "to" | "children">,
    React.RefAttributes<HTMLAnchorElement> {
  route: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>;
  locale?: string;
  children?: ReactNode | FC<{ isLoading: boolean }>;
}

export const LocaleLink = ({
  route,
  params,
  locale,
  children: Children,
  className,
  ...rest
}: LocaleLinkProps) => {
  const currentLocale = useLocale();
  const navigation = useNavigation();

  const [wasClicked, setClicked] = useState(false);

  useEffect(() => {
    if (navigation.state === "idle") setClicked(false);
  }, [navigation.state]);

  const isLoading = useMemo(
    () => navigation.state === "loading" && wasClicked,
    [navigation.state, wasClicked],
  );

  try {
    const to = getLinkByRoute(route, locale ?? currentLocale, params);

    return (
      <Link
        to={to}
        className={cn(className, {
          "pointer-events-none cursor-default": isLoading,
        })}
        onClick={(e) => {
          if (isLoading) {
            e.preventDefault();
            return;
          }
          setClicked(true);
        }}
        {...rest}
      >
        {typeof Children === "function" ? (
          <Children isLoading={isLoading} />
        ) : (
          <>{Children}</>
        )}
      </Link>
    );
  } catch (err) {
    console.error(err);
    return (
      <p>
        LinkError {route} {JSON.stringify(params)}
      </p>
    );
  }
};

interface LocaleNavLinkProps
  extends Omit<RemixNavLinkProps, "to">,
    React.RefAttributes<HTMLAnchorElement> {
  route: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>;
}

export const LocaleNavLink = ({
  route,
  params,
  ...rest
}: LocaleNavLinkProps) => {
  const locale = useLocale();
  try {
    const to = getLinkByRoute(route, locale, params);
    return (
      <NavLink to={to} {...rest}>
        {rest.children}
      </NavLink>
    );
  } catch (err) {
    console.error(err);
    return <p>LinkError {route}</p>;
  }
};
