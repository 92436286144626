import { generatePath, useNavigate } from "@remix-run/react";
import { useLocale } from "remix-i18next";
import { RouteDefinition, routes } from "~/services/routes";

const defaultLang = "sk";

export const getLinkByRoute = (
  linkRouteId: string,
  locale: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>,
) => {
  const findRoute = (routes: RouteDefinition[]): RouteDefinition | null => {
    for (const route of routes) {
      if (route.id === linkRouteId) return route;

      const foundTranslation = route.translations.find(
        (t) => t.lang === locale,
      );

      if (foundTranslation) {
        const foundNestedRoute = findRoute(foundTranslation.nestedRoutes ?? []);
        if (foundNestedRoute)
          return {
            ...foundNestedRoute,
            translations: foundNestedRoute.translations.map((t) => ({
              ...t,
              path: `${foundTranslation.path}/${t.path}`,
            })),
          };
      }
    }
    return null;
  };

  const route = findRoute(routes);

  const localPath = route?.translations.find((t) => t.lang === locale)?.path;

  if (route && localPath !== undefined) {
    if (!params) {
      return defaultLang === locale
        ? `/${localPath}`
        : `/${locale}/${localPath}`;
    }
    const path = generatePath(localPath, params);
    return defaultLang === locale ? `/${path}` : `/${locale}/${path}`;
  } else {
    throw new Error(`
      UNKNOWN ROUTE
      route: ${linkRouteId}
      locale: ${locale}
    `);
  }
};

type NavigateOptions = {
  replace?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
  preventScrollReset?: boolean;
  unstable_flushSync?: boolean;
  unstable_viewTransition?: boolean;
};

export const useLocaleNavigate = () => {
  const locale = useLocale();
  const navigate = useNavigate();

  return (route: string, options: NavigateOptions) => {
    const to = getLinkByRoute(route, locale);
    navigate(to, options);
  };
};
